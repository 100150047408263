.create-blog-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .create-blog-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    font-weight: bold;
  }
  
  /* input[type="text"],
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 5px;
  } */
  
  .submit-button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .create-blog-container {
      padding: 10px;
    }
  }
  